import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import(/* webpackChunkName: "about" */ '../views/PartnerView.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceView.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import(/* webpackChunkName: "about" */ '../views/ApplyView.vue')
  },
  {
    path: '/information',
    name: 'information',
    component: () => import(/* webpackChunkName: "about" */ '../views/InformationView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsList.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsDetail.vue')
  },
  {
    path: '/traffic',
    name: 'traffic',
    component: () => import(/* webpackChunkName: "about" */ '../views/trafficView.vue')
  },
  {
    path: '/hotal',
    name: 'hotal',
    component: () => import(/* webpackChunkName: "about" */ '../views/hotalView.vue')
  },
  {
    path: '/travel',
    name: 'travel',
    component: () => import(/* webpackChunkName: "about" */ '../views/travelView.vue')
  },
  {
    path: '/pdetail',
    name: 'pdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/PartnerDetail.vue')
  },
  {
    path: '/live',
    name: 'live',
    component: () => import(/* webpackChunkName: "about" */ '../views/liveView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 使用afterEach导航守卫
router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
