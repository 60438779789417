<template>
  <div>
    <div class="page home" @click.stop="saishi.show = false">
      <div class="logo"><img src="@/assets/image/logo322.png" alt=""></div>
      
      <!-- 活动赛事 -->
      <div class="saishi-btn" @click.stop="saishi.show = true"><img src="@/assets/image/img28.png" alt=""><em>赛事活动</em></div>
      <div class="saishi-layer" :class="{'show': saishi.show}" @click.stop="saishi.show = true">
        <div class="arrow"></div>
        <div class="close" @click.stop="saishi.show = false"></div>
        <div class="list">
          <li v-for="(item, index) in saishi.list" :key="index">
            <router-link :to="{ path: '/index', query: { ex_id: item.id } }">
              <div class="img"><img :src="baseDomain+item.img" alt=""></div>
              <!-- <div class="name">
                <em>{{item.time}}</em>
                <div>{{item.name}}</div>
              </div> -->
            </router-link>
          </li>
        </div>
      </div>

      <!-- 导航菜单 -->
      <div class="navA" @click="navShow = true"></div>
      <div class="nav" :class="{'show': navShow}">
        <div class="line" ref="line"></div>
        <div class="close" @click="navShow = false"></div>
        <ul>
          <li v-for="(item, index) in navList" :key="index" :class="{'on': index === navCurr}">
            <div class="name" ref="nav-dot">
              <em>0{{index+1}}</em>
              <span>{{item}}</span>
            </div>
          </li>
        </ul>
      </div>

      <div id="home-wrapper" class="swiper">
        <div class="swiper-wrapper">

          <!-- 顶部banner -->
          <div class="swiper-slide index-banner">
            <div id="banner">
              <ul class="swiper-wrapper">
                <li class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
                  <div class="bg" v-if="isPhone"><img :src="baseDomain+item.imgPhone" alt=""></div>
                  <div class="bg" v-else><img :src="baseDomain+item.img" alt=""></div>
                  <div class="container">
                    <div class="logo" v-if="isPhone" ref="banner-logo"><img src="@/assets/image/logo144.png" alt=""></div>
                    <div class="title" ref="banner-title"><img :src="baseDomain+item.title" alt=""></div>
                    <div class="more" ref="banner-more"><router-link :to="{ path: '/index', query: { ex_id: 2 } }"></router-link></div>
                    <!-- :to="{ path: '/index', query: { ex_id: item.id } }" -->
                  </div>
                </li>
              </ul>
              <div class="dots">
                <div v-for="(item, index) in bannerList.length" :class="{'on': bannerListCurr === index}" :key="index" ref="bannerDot">
                  <span>{{item}}</span>
                </div>
              </div>
            </div>
          </div>
          
          <!-- 新闻资讯 -->
          <div class="swiper-slide news">
            <div class="bg" v-if="isPhone"><img :src="news.bgPhone" alt=""></div>
            <div class="bg" v-else><img :src="news.bg" alt=""></div>
            <div class="wal">
              <div class="logo" ref="news-logo" v-if="isPhone"><img src="@/assets/image/logo144.png" alt=""></div>
              <div class="bd">
                <div class="title" ref="news-title"><span>新闻</span><em>资讯</em></div>
                <p ref="news-p">{{newsInfo.description}}</p>
                <div ref="news-arrow" class="arrow">
                  <a href="javascript:" class="prev" @click="newsSwiper.slidePrev()"></a>
                  <a href="javascript:" class="next" @click="newsSwiper.slideNext()"></a>
                </div>
              </div>
              <div class="list" ref="news-list">
                <div class="list-wrapper" id="news-swiper">
                  <ul class="swiper-wrapper">
                    <li class="swiper-slide" v-for="(item, index) in news.list" :key="item.id" :class="{'odd': index % 2}">
                      <router-link :to="{ path: '/detail', query: { ex_id: item.group_id, id: item.id } }">
                      <!-- <router-link to="/detail/"> -->
                        <div class="img" v-if="isPhone"><img :src="baseDomain+item.imgPhone" alt=""></div>
                        <div class="img" v-else><img :src="baseDomain+item.img" alt=""></div>
                        <div class="name">{{item.name}}</div>
                        <div class="time">
                          <p>{{item.time}}</p>
                          <em>详情 ></em>
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="arrow">
                  <a href="javascript:" class="prev" @click="newsSwiper.slidePrev()"></a>
                  <a href="javascript:" class="next" @click="newsSwiper.slideNext()"></a>
                </div>
              </div>
            </div>
          </div>

          <!-- 精彩视频 -->
          <div class="swiper-slide index-video">
            <div class="bg" v-if="isPhone"><img :src="indexVideo.bgPhone" alt=""></div>
            <div class="bg" v-else><img :src="indexVideo.bg" alt=""></div>
            <div class="wal">
              <div class="logo" ref="video-logo" v-if="isPhone"><img src="@/assets/image/logo144.png" alt=""></div>
              <div class="title" ref="video-title" v-if="isPhone"><span>精彩</span><em>视频</em></div>
              <div class="side" ref="video-side" @click="handelIndexVideo(indexVideo.side)">
                <router-link to="/">
                  <div class="video-btn"></div>
                  <div class="zoomImg"><img :src="baseDomain+indexVideo.side.img" alt=""></div>
                  <div class="bd">
                    <div class="name">{{indexVideo.side.name}}</div>
                    <p>{{indexVideo.side.content}}</p>
                  </div>
                </router-link>
              </div>
              <div class="list" ref="video-list">
                <div class="list-wrapper" id="indexVideoSwiper">
                  <ul class="swiper-wrapper">
                    <li class="swiper-slide" v-for="(list, index) in indexVideo.list" :key="index" >
                      <dl>
                        <dd v-for="item in list" :key="item.id">
                          <a href="javascript:" @click="handelIndexVideo(item)">
                            <div class="video-btn" v-if="item.video"></div>
                            <div class="zoomImg"><img :src="baseDomain+item.img" alt=""></div>
                            <div class="bd">
                              <div class="name">{{item.name}}</div>
                              <p v-if="isPhone">{{item.content}}</p>
                            </div>
                          </a>
                        </dd>
                      </dl>
                    </li>
                  </ul>
                </div>
                <div class="arrow">
                  <a href="javascript:" class="prev" @click="indexVideoSwiper.slidePrev()"></a>
                  <a href="javascript:" class="next" @click="indexVideoSwiper.slideNext()"></a>
                </div>
              </div>
            </div>
          </div>
          
          <!-- 合作伙伴 -->
          <div class="swiper-slide index-partner">
            <div class="bg" v-if="isPhone"><img :src="partner.bgPhone" alt=""></div>
            <div class="bg" v-else><img :src="partner.bg" alt=""></div>
            <div class="wal">
              <div class="logo" ref="partner-logo" v-if="isPhone"><img src="@/assets/image/logo144.png" alt=""></div>
              <div class="bd">
                <div class="title" ref="partner-title"><span>合作</span><em>伙伴</em></div>
                <p ref="partner-p">{{partnerInfo.description}}</p>
              </div>
              <div class="list" ref="partner-list">
                <div class="list-wrapper" id="partnerSwiper">
                  <ul class="swiper-wrapper">
                    <li class="swiper-slide" v-for="(list, index) in partner.list" :key="index">
                      <dl>
                        <dd v-for="item in list" :key="item.id">
                          <router-link to="/">
                            <div class="zoomImg"><img :src="baseDomain+item.img" alt=""></div>
                            <div class="name">{{item.name}}</div>
                          </router-link>
                        </dd>
                      </dl>
                    </li>
                  </ul>
                </div>
                <div class="arrow">
                  <a href="javascript:" class="prev" @click="partnerSwiper.slidePrev()"></a>
                  <a href="javascript:" class="next" @click="partnerSwiper.slideNext()"></a>
                </div>
              </div>
            </div>
          </div>
          
          <!-- 关于我们 -->
          <div class="swiper-slide index-about">
            <div class="bg" v-if="isPhone"><img :src="about.bgPhone" alt=""></div>
            <div class="bg" v-else><img :src="about.bg" alt=""></div>
            <div class="wal">
              <div class="logo" ref="about-logo" v-if="isPhone"><img src="@/assets/image/logo144.png" alt=""></div>
              <div class="bd">
                <div class="title" ref="about-title"><span>关于</span><em>我们</em></div>
                <p ref="about-p">{{aboutInfo.desc}}</p>
                <div ref="about-more" class="more"><router-link to="/about"></router-link></div>
              </div>
              <div class="container">
                <div class="img" ref="about-img">
                  <div class="zoomImg"><img :src="baseDomain+aboutInfo.img" alt=""></div>
                </div>
                <div class="content" ref="about-content">
                  <p class="p-2">
                    {{aboutInfo.content}}
                  </p>
                  
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
    <VideoLayer :video="videoLayer" @close="videoLayer.show = false"></VideoLayer>
    <ImageLayer :imgLayer="imgLayer" @close="imgLayer.show = false"></ImageLayer>
  </div>
</template>

<script>
import Swiper from 'swiper';
import { Mousewheel, Keyboard } from 'swiper/modules';
import VideoLayer from "@/components/VideoLayer.vue";
import ImageLayer from "@/components/ImageLayer.vue";
import _ from 'lodash';
import { gsap } from 'gsap';
export default {
  components: {VideoLayer, ImageLayer},
  data() {
    return{
      isPhone: window.innerWidth <= 800,
      navCurr: 0,
      navShow: false,
      navList: ['首页', '新闻资讯', '精彩视频', '合作伙伴', '关于我们'],
      lineH: '20%',
      bannerList: [
        {img: './image/img1920_1.jpg', imgPhone: './image/img750-1.jpg', title: './image/img749.png'},
      ],
      bannerListCurr: 0,
      partnerSwiper: null,
      partner: {
        bg: './image/img1920_2.jpg',
        bgPhone: './image/img750-2.jpg',
        list: []
      },
      indexVideoSwiper: null,
      indexVideo: {
        bg: './image/img1920_3.jpg',
        bgPhone: './image/img750-2.jpg',
        side: {
          img: '',
          name: '',
          content: ''
        },
        list: []
      },
      newsSwiper: null,
      news: {
        bg: './image/img1920_3.jpg',
        bgPhone: './image/img750-2.jpg',
        list: []
      },
      about: {
        bg: './image/img1920_5.jpg',
        bgPhone: './image/img750-2.jpg',
        img: './image/img420.jpg',
      },
      saishi: {
        show: false,
        list: [
          // {img: './image/img360-1.png', name: '中国杭州桐庐马术公开赛', time: '2023年第一届', id:1},
        ]
      },
      videoLayer: {
        show: false,
        title: '',
        src: ''
      },
      imgLayer: {
        curr: 0,
        show: false,
        list: []
      },      
      bannerTl: new gsap.timeline({paused: true}),
      newsTl: new gsap.timeline({paused: true}),
      videoTl: new gsap.timeline({paused: true}),
      partnerTl: new gsap.timeline({paused: true}),
      aboutTl: new gsap.timeline({paused: true}),
      DataList:[],
      aboutInfo:[],
      newsInfo:[],
      partnerInfo:[],
    }
  },
  mounted() {
    this.getDataList()
    this.init()
    this.indexBanner()
    this.getPartner()
    this.getIndexVideo()
    this.newsFun()
    this.aboutFun()
  },
  watch: {
    navCurr(val){
      switch (val) {
        case 0:
          this.bannerTl.play(0)
          break
        case 1:
          this.newsTl.play(0)
          break
        case 2:
          this.videoTl.play(0)
          break
        case 3:
          this.partnerTl.play(0)
          break
        case 4:
          this.aboutTl.play(0)
          break
      }
    }
  },
  methods:{
    getDataList(){
      //获取首页数据
      let that = this
      this.$http.get(that.baseDomainUrl+'home/index')
                .then( (res) => {                  
                  this.DataList = res.data.data
                  this.bannerList = res.data.data.banner
                  this.news.list = res.data.data.news
                  this.aboutInfo = res.data.data.index.about
                  this.newsInfo = res.data.data.index.news
                  this.partnerInfo = res.data.data.index.parten
                  this.saishi.list = res.data.data.saishi
                  // console.log('aboutInfo:',this.partner.list)
                  // console.log('DataList:',this.DataList)
                  // console.log('res:',res)
                }).catch( (err) => {
                  console.log(err)
                });
    },
    init() {
      const that = this
      const swiper = new Swiper('#home-wrapper', {
        direction: 'vertical',
        mousewheel: true,
        keyboard : true,
        slidesPerView: 'auto',
        initialSlide: this.navCurr,
        modules: [Mousewheel, Keyboard],
        speed: 600,
        on:{
          slideChange(swiper){
            that.navCurr = swiper.activeIndex
            that.lineH = (swiper.activeIndex+1)*20 + '%'
            that.$refs['line'].style.setProperty('--h', that.lineH)
          }
        }
      })
      this.$refs['nav-dot'].forEach((item, index)=>{
        item.addEventListener('click', ()=>{
          swiper.slideTo(index)
          this.navShow = false
        })
      })
      this.bannerTl.play(0)
    },
    indexBanner(){
      
      const swiper = new Swiper('#banner', {
        slidesPerView: 1,
        speed: 600,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        on:{
          slideChange: (swiper)=>{
            this.bannerListCurr = swiper.activeIndex
          }
        }
      })
      this.$refs['bannerDot'].forEach((item, index)=>{
        item.addEventListener('click', ()=>{
          swiper.slideTo(index)
        })
      })

      if(this.isPhone){
        this.$refs['banner-logo'].forEach(item=>{
          this.bannerTl.add(gsap.fromTo(item, {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), .5)
        })
        this.$refs['banner-title'].forEach(item=>{
          this.bannerTl.add(gsap.fromTo(item, {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), .8)
        })
        this.$refs['banner-more'].forEach(item=>{
          this.bannerTl.add(gsap.fromTo(item, {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), 1.1)
        })
      }else{
        this.$refs['banner-title'].forEach(item=>{
          this.bannerTl.add(gsap.fromTo(item, {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), .5)
        })
        this.$refs['banner-more'].forEach(item=>{
          this.bannerTl.add(gsap.fromTo(item, {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), .8)
        })
      }
    },
    getPartner(){
      let that = this
      this.$http.get(that.baseDomainUrl+'home/partner')
                .then( (res) => {                  
                  const list =  res.data.data
                  this.partner.list = _.chunk(list, 6)
                  this.partnerSwiper = new Swiper('#partnerSwiper', {
                        slidesPerView: 1,
                        speed: 600,
                        loop: true,
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: false,
                        }
                  })
                  // console.log('partnerList:',res.data)
                }).catch( (err) => {
                  console.log(err)
                });      

      if(this.isPhone){
        this.partnerTl.add(gsap.fromTo(this.$refs['partner-logo'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), .5)
        this.partnerTl.add(gsap.fromTo(this.$refs['partner-title'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
        this.partnerTl.add(gsap.fromTo(this.$refs['partner-list'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
      }else{
        this.partnerTl.add(gsap.fromTo(this.$refs['partner-title'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), .5)
        this.partnerTl.add(gsap.fromTo(this.$refs['partner-p'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), '-=.2')
        this.partnerTl.add(gsap.fromTo(this.$refs['partner-list'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), '-=.2')
      }
    },
    getIndexVideo(){
      let that = this
      this.$http.get(that.baseDomainUrl+'home/jincaivideo')
                .then( (res) => {        
                  this.indexVideo.side = res.data.data.side   
                  const list =  res.data.data.list
                  this.indexVideo.list = _.chunk(list, this.isPhone ? 1 : 4)
                  this.indexVideoSwiper = new Swiper('#indexVideoSwiper', {
                    slidesPerView: 1,
                    speed: 600,
                    loop: true,
                    autoplay: {
                      delay: 5000,
                      disableOnInteraction: false,
                    }
                  })
                  // console.log('partnerList:',res.data)
                }).catch( (err) => {
                  console.log(err)
                });  



      // const list = [
      //   {img: './image/img440_1.jpg', name: '中华台北选手谷筱霜夺冠', content: '10月8日上午，杭州第19届亚运会空手道女子组手-50公斤级金', video: 'https://www.nio.cn/cdn-static/luban/nextjs/images/44fb68805d2900f98544bd00937d735c/home/top-carousel-et5-touring-video.mp4'},
      //   {img: './image/img440_2.jpg', name: '中华台北选手谷筱霜夺冠', content: '10月8日上午，杭州第19届亚运会空手道女子组手-50公斤级金'},
      //   {img: './image/img440_3.jpg', name: '豪拉和伊朗选手莎拉·巴赫曼亚尔获', content: '10月8日上午，杭州第19届亚运会空手道女子组手-50公斤级金'},
      //   {img: './image/img440_4.jpg', name: '中华台北选手谷筱霜夺冠', content: '10月8日上午，杭州第19届亚运会空手道女子组手-50公斤级金', video: 'https://www.nio.cn/cdn-static/luban/nextjs/images/44fb68805d2900f98544bd00937d735c/home/top-carousel-et5-touring-video.mp4'},
      // ]
      // this.indexVideo.list = _.chunk(list, this.isPhone ? 1 : 4)
      // this.indexVideoSwiper = new Swiper('#indexVideoSwiper', {
      //   slidesPerView: 1,
      //   speed: 600,
      //   loop: true,
      //   autoplay: {
      //     delay: 5000,
      //     disableOnInteraction: false,
      //   }
      // })

      if(this.isPhone){
        this.videoTl.add(gsap.fromTo(this.$refs['video-logo'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), .5)
        this.videoTl.add(gsap.fromTo(this.$refs['video-title'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
        this.videoTl.add(gsap.fromTo(this.$refs['video-list'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
      }else{
        this.videoTl.add(gsap.fromTo(this.$refs['video-side'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), .5)
        this.videoTl.add(gsap.fromTo(this.$refs['video-list'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), '-=.2')
      }
    },
    newsFun(){
           
      this.newsSwiper = new Swiper('#news-swiper', {
        slidesPerView: this.isPhone ? 1 : 3,
        speed: 600,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        }
      })

      if(this.isPhone){
        this.newsTl.add(gsap.fromTo(this.$refs['news-logo'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), .5)
        this.newsTl.add(gsap.fromTo(this.$refs['news-title'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
        this.newsTl.add(gsap.fromTo(this.$refs['news-list'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
      }else{
        this.newsTl.add(gsap.fromTo(this.$refs['news-title'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), .5)
        this.newsTl.add(gsap.fromTo(this.$refs['news-p'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), '-=.2')
        this.newsTl.add(gsap.fromTo(this.$refs['news-arrow'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), '-=.2')
        this.newsTl.add(gsap.fromTo(this.$refs['news-list'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), '-=.2')
      }
    },
    handelIndexVideo(item){
      if(item.video){
        this.openVideo(item)
      }else{
        this.openImgLayer(item)
      }
    },
    aboutFun(){
      if(this.isPhone){
        this.aboutTl.add(gsap.fromTo(this.$refs['about-logo'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), .5)
        this.aboutTl.add(gsap.fromTo(this.$refs['about-title'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
        this.aboutTl.add(gsap.fromTo(this.$refs['about-content'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
        this.aboutTl.add(gsap.fromTo(this.$refs['about-img'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
      }else{
        this.aboutTl.add(gsap.fromTo(this.$refs['about-title'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), .5)
        this.aboutTl.add(gsap.fromTo(this.$refs['about-p'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), '-=.2')
        this.aboutTl.add(gsap.fromTo(this.$refs['about-more'], {duration: .5, x: 100, opacity: 0}, {x: 0, opacity: 1}), '-=.2')
        this.aboutTl.add(gsap.fromTo(this.$refs['about-img'], {duration: .5, y: 100, opacity: 0}, {y: 0, opacity: 1}), '-=.2')
        this.aboutTl.add(gsap.fromTo(this.$refs['about-content'], {duration: .5, x: -100, opacity: 0}, {x: 0, opacity: 1}), '-=.2')
      }
    },
    openVideo(item){
      this.videoLayer.show = true
      this.videoLayer.src = item.video
      this.videoLayer.title = item.name
    },
    openImgLayer(item){
      let that = this
      this.$http.post(that.baseDomainUrl+'home/imagelist',{id:item.id})
                .then( (res) => {        
                  this.imgLayer.list = res.data.data   
                  // console.log('imgLayerList:',res.data)
                }).catch( (err) => {
                  console.log(err)
                });  

      this.imgLayer.show = true
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/page";

.page{background: #F4F4F4; position: fixed; left: 0; top: 0; right: 0; bottom: 0}

</style>
