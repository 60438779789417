export default {
	/*
	 * 替换字符串中的网址，或给图片加上网址
	 * str 需要替换的字符串
	 * url  需要替换的url网址
	 * type 操作类型  默认  为替换字符串中所有网址
	 *                img   给字符串中图片加网址
	 *                file  给字符串中问价加网址
	 */
	replaceDomain(str, url, type) {
		if (!str && !url) {
			return str
		}
		let result = str
		//默认执行删除域名
		if (!type) {
			let count = (str.match(/http/g) || []).length;
			for (let i = 0; i < count; i++) {
				result = result.replace(url, "");
			}
		}
		if (type == 'img') {
			let imgcount = (str.match(/src=/g) || []).length;
			for (let i = 0; i < imgcount; i++) {
				result = result.replace('src="', 'src="' + url);
			}
		}

		return result

	},

	//替换字符串中图片的地址加上网址
	replaceDomainImg(str, url) {
        if (!str && !url) {
          return str
        }
        let result = str        
        let imgcount = (str.match(/src=/g) || []).length;
        for (let i = 0; i < imgcount; i++) {
			result = result.replace('src="/', 'src="' + url + '/');
        } 
        return result
      },

	//截取字符串逗号前文字
	substrDouhao(value) {
		if (!value) return ''
		value = value.toString()
		let count = value.indexOf(',')

		return value.substring(0, count)
	},

	



}