<template>
  <div class="img-layer" :class="{'show': imgLayer.show}">
    <div class="container">
      <div class="close" @click="$emit('close')"></div>
      <div class="big-img">
        <div class="list-wrapper" id="bigImg">
          <ul class="swiper-wrapper">
            <li class="swiper-slide" v-for="(item, index) in imgLayer.list" :key="index">
              <div class="img"><img :src="baseDomain+item.bigImg" alt=""></div>
              <div class="name">{{item.title}}</div>
            </li>
          </ul>
        </div>
        <div class="arrow">
          <a href="javascript:" class="prev" @click="swiperPrev"></a>
          <a href="javascript:" class="next" @click="swiperNext"></a>
        </div>
      </div>
      <div class="img-list">
        <div class="list-wrapper" id="imgList">
          <ul class="swiper-wrapper">
            <li class="swiper-slide" v-for="(item, index) in imgLayer.list" :key="index">
              <div class="img" :class="{'on': curr === index}" @click="curr = index"><img :src="baseDomain+item.img" alt=""></div>
            </li>
          </ul>
        </div>
        <div class="arrow" v-if="imgLayer.list.length > slidesPerView">
          <a href="javascript:" class="prev" @click="swiperPrev"></a>
          <a href="javascript:" class="next" @click="swiperNext"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  props:{
    imgLayer: {
      default(){
        return{}
      },
      type: Object
    }
  },
  data(){
    return {
      curr: 0,
      slidesPerView: window.innerWidth > 800 ? 6 : 4,
      bigSwiper: null,
      imgListSwiper: null,
    }
  },
  mounted() {
    this.curr = this.imgLayer.curr || 0
    this.init()
  },
  watch:{
    curr: function(newValue){
      this.bigSwiper.slideTo(newValue)
      this.imgListSwiper.slideTo(newValue-2)
    }
  },
  methods:{
    init(){
      this.bigSwiper = new Swiper('#bigImg', {
        slidesPerView: 1,
        speed: 600,
        loop: true,
        on:{
          slideChange: (swiper)=>{
            this.bannerListCurr = swiper.activeIndex
          }
        }
      })
      this.imgListSwiper = new Swiper('#imgList', {
        slidesPerView: this.slidesPerView,
        speed: 600,
        //loop: true,
        on:{
          slideChange: (swiper)=>{
            this.bannerListCurr = swiper.activeIndex
          }
        }
      })
    },
    swiperPrev(){
      if(this.curr > 0){
        this.curr --
      }else{
        this.curr = this.imgLayer.list.length - 1
      }
    },
    swiperNext(){
      if(this.curr < this.imgLayer.list.length - 1){
        this.curr++
      }else{
        this.curr = 0
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/page";
.img-layer{position: fixed; z-index: 99; left: 0; top: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, .8); opacity: 0; visibility: hidden; transition: .5s; display: flex; align-items: center; justify-content: center;
  &.show{opacity: 1; visibility: visible;}
  .container{width: 15rem; position: relative; color: #fff}
  .close{position: absolute; z-index: 5; cursor: pointer; top: 0; right: -.5rem; font-size: .24rem;
    &:after{content: '\e616'}
  }
  .arrow a{display: flex; align-items: center; justify-content: center; position: absolute; z-index: 5; top: 50%; transform: translateY(-50%); width: .4rem; height: 1rem; background: #000; font-size: .2rem; color: #fff; transition: .5s; border-radius: .06rem;
    &:hover{background-color: #1C81D4}
    &.prev:after{content: '\e659'}
    &.next:after{content: '\e667'}
  }
  .big-img{position: relative;
    .list-wrapper{width: 100%; overflow: hidden; position: relative; border-radius: .1rem}
    li{position: relative; text-align: center;
      &:after{@include before(); left: 0; bottom: 0; right: 0; height: 2rem; background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8))}
      //img{border-radius: .1rem; height: calc(100vh - 3rem); width: 100%;}
      img{border-radius: .1rem; height: calc(100vh - 3rem);}
      .name{position: absolute; z-index: 5; left: 0; bottom: 0; right: 0; color: #fff; font-size: .24rem; padding: .3rem .4rem}
    }
    .arrow a{
      &.prev{left: -.9rem}
      &.next{right: -.9rem}
    }
  }
  .img-list{position: relative; padding: 0 .5rem; margin-top: .4rem;
    .list-wrapper{width: 100%; overflow: hidden; position: relative}
    li{padding: 0 .11rem;
      .img{position: relative; cursor: pointer;
        &:after{@include before(); z-index: 5; left: 0; top: 0; right: 0; bottom: 0; border: #1C81D4 solid 1px; border-radius: .06rem; opacity: 0; background: rgba(0, 0, 0, .5)}
        img{width: 100%; height: 1.3rem; border-radius: .06rem}
      }
      .img.on:after{opacity: 1}
    }
    .arrow a{
      &.prev{left: 0; height: 100%}
      &.next{right: 0; height: 100%}
    }
  }
}

@media screen and (max-width: 800px){
  .img-layer{
    .container{width: 7.5rem}
    .close{right: .3rem; top: -.95rem; font-size: .38rem}
    .arrow a{width: .4rem; font-size: .28rem}
    .big-img{
      .arrow{display: none}
      li{padding: 0 .3rem;
        &:after{height: 50%}
        img{height: 4rem}
        .name{padding: .3rem .6rem}
      }
      .arrow a{
        &.prev{left: -.9rem}
        &.next{right: -.9rem}
      }
    }
    .img-list{position: relative; padding: 0 .5rem; margin-top: .4rem;
      li{padding: 0 .11rem;
        .img{
          img{height: 1rem}
        }
      }
    }
  }
}
</style>
