<template>
  <div class="video-layer" :class="{'show': video.show}">
    <div class="container">
      <div class="close" @click="$emit('close')"></div>
      <div class="title">{{video.title}}</div>
      <video v-if="video.show" webkit-playsinline="true" playsinline x5-video-player-type="h5" x-webkit-airplay="true" controls muted autoplay>
        <source :src="video.src" type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    video: {
      default(){
        return{}
      },
      type: Object
    }
  }
}
</script>

<style scoped lang="scss">
.video-layer{position: fixed; z-index: 99; left: 0; top: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, .8); opacity: 0; visibility: hidden; transition: .5s; display: flex; align-items: center; justify-content: center;
  &.show{opacity: 1; visibility: visible;}
  .container{width: 15rem; position: relative; color: #fff}
  .close{position: absolute; z-index: 5; cursor: pointer; top: 0; right: 0; font-size: .24rem;
    &:after{content: '\e616'}
  }
  .title{font-size: .24rem; padding: 0 0 .3rem .13rem}
  video{display: block; width: 100%; border-radius: .1rem; max-height: 80vh;}
}
@media screen and (max-width: 800px){
  .video-layer{
    .container{width: 7.5rem}
    .close{right: .3rem; font-size: .38rem; top: -.1rem}
    .title{font-size: .28rem; padding: 0 0 .3rem .3rem}
  }
}
</style>
